import * as types from '../reducers/types'

export const toggleSideNav =(payload)=>{
    return {
        type: types.TOGGLE_SIDE_NAV,
        payload:payload,
    }
}

export const closeModal = () => {
    return {
        type: types.CLOSE_MODAL
    }
}

export const openModal = (modalType, chkbtn, modalData = {}) => {
    return {
        type: types.OPEN_MODAL,
        payload: { modalType, chkbtn, modalData }
    }
}

export const setSelectedMenu = (id)=>{
    return {
        type: types.SELECTED_MENU,
        payload:id
    }
}

export const toggleSideSheet = (payload)=>{
    return {
        type: types.TOGGLE_SIDE_SHEET,
        payload:payload
    }
}

export const setSelectedSupplimentaryReq = (course,menuId)=>{
    return {
        type: types.SELECTED_SUPPLIMENTARY_REQ,
        payload:{course,menuId}
    }
}

export const setSelectedHistoryReq = (course,menuId,allCourseStructure)=>{
    return {
        type: types.SELECTED_HISTORY_REQ,
        payload:{course,menuId,allCourseStructure}
    }
}
