/**
 * Generate an ECharts heatmap option for a calendar visualization.
 *
 * @param {number} maxValue - The maximum value for the heatmap.
 * @param {Array<Array>} seriesData - The data series for the heatmap.
 * @param {Array<string>} range - The date range for the calendar.
 * @param {object} options - Options of Echart for customixation or overwriting the default options(look in Echarts for more details).
 * @returns {object} - The ECharts heatmap option object.
 */
export const optionCalenderHeatmap = (maxValue, seriesData, range, options) => {
  const intervalSize = maxValue / 5;
  return {
    title: {
      top: 0,
      left: "center",
      // text: "Daily Step Count",
    },
    tooltip: {
      formatter: function (params) {
        const date = params.data[0];
        const count = params.data[1];
        const heatmapColor = params.color
          ? `style="background-color: ${params.color}"`
          : "";
        return `<div class="tooltip-box">
                <div class="box-clr-ct">
                <div class="box-color" ${heatmapColor}></div>
                </div>
                <div>
                <div>${date}</div>
                <div>${count}</div>
                
                </div>
              </div>`;
      },
      position: "top",
    },
    visualMap: [
      {
        type:'piecewise',
        min: 0,
        max: maxValue,
        itemGap: 0,
        itemSymbol:"rect",
        // color: [
        //   "#e6f7ed",
        //   "#b3e6ca",
        //   "#66cd95",
        //   "#00ab4f",
        //   "#00893f",
        //   "#005628",
        // ].reverse(),
        pieces: [
          { min: 0, max: 0, color: '#e6f7ed' }, 
          { min: 1, max: intervalSize, color: '#b3e6ca' }, 
          { min: intervalSize, max: 2*intervalSize, color: '#66cd95' }, 
          { min: 2*intervalSize, max: 3*intervalSize, color: '#00ab4f' }, 
          { min: 3*intervalSize, max: 4*intervalSize, color: '#00893f' }, 
          { min: 4*intervalSize, max: maxValue, color: '#005628' }, 
      ],
        showLabel: false,
        text:['   High', 'Low   '], 
        calculable: true,
        orient: "horizontal",
        right: "20",
        bottom:390,
        // top: 65,
      },
      // {
      //   min: 0,
      //   max: maxValue,
      //   color: [
      //     "#e6f7ed",
      //     "#b3e6ca",
      //     "#66cd95",
      //     "#00ab4f",
      //     "#00893f",
      //     "#005628",
      //   ].reverse(),
      //   calculable: true,
      //   orient: "horizontal",
      //   left: "center",
      //   top: 20,
      // },
    ],
    calendar: {
      top: 80,
      left: 60,
      right: 30,
      cellSize: ["auto", 24],
      range: range,
      itemStyle: {
        borderWidth: 2,
        borderColor: "transparent",
      },
      splitLine: {
        lineStyle: {
          color: "black",
        },
      },
      yearLabel: { show: false },
    },
    series: [
      {
        type: "heatmap",
        color: ["blue", "red", "yellow", "green"],
        coordinateSystem: "calendar",
        data: seriesData,
      },
    ],
    ...options,
  };
};

/**
 * Generate ECharts options for a Cartesian heatmap chart.
 *
 * @param {number} maxValue - Maximum visual value for the heatmap.
 * @param {Array} seriesData - Data array for the heatmap, containing [x, y, value] entries.
 * @param {Array} dataX - X-axis data array.
 * @param {Array} dataY - Y-axis data array.
 * @param {string} name - Name of the heatmap series.
 * @param {object} options - Options of Echart for customixation or overwriting the default options(look in Echarts for more details).
 * @returns {object} - ECharts options for the Cartesian heatmap chart.
 */
export const optionCartesianHeatmap = (
  maxValue,
  seriesData,
  dataX,
  dataY,
  name,
  options
) => {
  return {
    tooltip: {
      position: "bottom",
    },
    grid: {
      height: "40%",
      top: "10%",
      left: "4%",
      width: "90%",
      // show: true,
      // backgroundColor: "#ddd",
      // borderWidth: 1,
      // borderColor: "#000",
    },
    xAxis: {
      type: "category",
      data: dataX,
      name:"day",
      // show: false,
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: "category",
      data: dataY,
      // show: false,
      splitArea: {
        show: true,
      },
    },
    visualMap: [
      // {
      //   min: 0,
      //   max: maxValue,
      //   color: [
      //     "#e6f7ed",
      //     "#b3e6ca",
      //     "#66cd95",
      //     "#00ab4f",
      //     "#00893f",
      //     "#005628",
      //   ].reverse().reverse(),
      //   calculable: true,
      //   orient: "horizontal",
      //   left: "center",
      //   top: "0",
      // },
      
      {
        type:'piecewise',
        min: 0,
        max: maxValue,
        itemGap: 0,
        itemSymbol:"rect",
        color: [
          "#e6f7ed",
          "#b3e6ca",
          "#66cd95",
          "#00ab4f",
          "#00893f",
          "#005628",
        ].reverse(),
        showLabel: false,
        text:['   High', 'Low   '], 
        calculable: true,
        orient: "horizontal",
        right: "20",
        bottom:275,
      },
    ],
    series: [
      {
        name: name,
        type: "heatmap",
        data: seriesData.map(function (item) {
          return [item[0], item[1], item[2] || "-"];
        }),
        label: {
          show: false,
        },

        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
    ...options,
  };
};


export const optionLineChart = (options, dataX, dataY) => {
  return {
    tooltip: {
      trigger: 'axis', // Show tooltips when hovering over data points along the axis
      axisPointer: { // Line style of axis pointer
          type: 'line', // Use a line as the axis pointer
          lineStyle: {
              color: '#aaa' // Color of the axis pointer line
          }
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: dataX
    },
    grid: {
      top: '5%',    
      // bottom: '20%', 
      // left: '10%',   
      // right: '10%'   
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: dataY,
        type: 'line',
        areaStyle: {
          color: options?.areaStyleColor || "#8a9cd8", // Set the color of the area under the graph
        },
        lineStyle: {
          color:  options?.lineStyleColor || "#7c8cc2"// Set the color of the line
        },
        itemStyle: {
          color: options?.itemStyleColor || "#7c8cc2" // Set the color of points on the line
        }
      }
    ],
   
  };
}
export const optionPieChart = (options,data) => {
  return  {
    // title: {
    //   text: 'Referer of a Website',
    //   subtext: 'Fake Data',
    //   left: 'center'
    // },
    tooltip: {
      trigger: 'item',
      position: ['30%', '70%'],
      extraCssText: 'width: 300px; white-space: normal;'
    },
    // legend: {
    //   orient: 'vertical',
    //   left: 'left'
    // },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        center: ['50%', '45%'],
        radius: '60%',
        data: data || [
          { value: 335, name: 'Category 1',  }, // Green
          { value: 310, name: 'Category 2',  }, // Yellow
          { value: 234, name: 'Category 3',  }, // Blue
          { value: 135, name: 'Category 4',  }, // Orange
          { value: 548, name: 'Category 5',  }, // Purple
          { value: 435, name: 'Category 6',  }, // Pink
          { value: 278, name: 'Category 7',  }, // Brown
          { value: 173, name: 'Category 8',  }, // Grayish Blue
          { value: 109, name: 'Category 9',  }, // Amber
          { value: 457, name: 'Category 10',  }, // Yellowish
          // { value: 380, name: 'Category 11',  }, // Indigo
          // { value: 248, name: 'Category 12',  }, // Cyan
          // { value: 195, name: 'Category 13',  }, // Yellowish
          // { value: 162, name: 'Category 14',  }, // Light Green
          // { value: 134, name: 'Category 15',  }, // Lime
          // { value: 355, name: 'Category 16',  }, // Gray
          // { value: 290, name: 'Category 17',  }, // Light Blue
          // { value: 203, name: 'Category 18',  }, // Purple
          // { value: 168, name: 'Category 19',  }, // Orange
          // { value: 87, name: 'Category 20',  }  // Amber
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };
}
