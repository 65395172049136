import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from 'react-router-dom';
import StatisticsChartRender from "./StatisticsChartRender";
import { useDispatch, useSelector } from "react-redux";
import { statsDataRequest, setStatisticTabData } from "../../redux/actions/userActions";
// import { companyDataRequest } from "../../redux/actions/userActions";
import Box from "@mui/material/Box";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Statistics.scss";
import { useToasts } from "react-toast-notifications";
import CommonSelect from "./CommonSelect/CommonSelect";
import * as userActions from '../../redux/actions/userActions';
import * as utilityActions from '../../redux/actions/utilityActions'
import StatisticsInfoChartsRender from "./StatisticsInfoChartsRender";
import SideSheet from "../sidesheet/SideSheet";

const Statistics = () => {
  const [data, setData] = useState({});
  // const [show, setShow] = useState(false);
  const [collapsed, setCollapsed] = useState(false)
  const [isInitialRender, setIsInitialRender] = useState(true);
  // const [clientId, setClientId] = useState(0)
  // const [clientName, setClientName] = useState("")
  // const [dateFrom, setDateFrom] = useState("")
  const [heightInteraction, setHeightInteraction] = useState(0)
  const [heightInteractionCont, setHeightInteractionCont] = useState(0)
  // const [dateTo, setDateTo] = useState("")
  const [pointerDate, setPointerDate] = useState({})
  const monthRef = useRef(null);
  const weekRef = useRef(null);
  const dateRef = useRef(null);
  const [weeks, setWeek] = useState({});
  // const [textData,setTextData]=useState([])
  const [minuteData,setMinuteData]=useState([])
  const interactionGraphRef = useRef(null)
  const graphHeaderRef = useRef(null)
  const { addToast } = useToasts();
  // const [select, setSelect] = useState({
  //   yearSel: "",
  //   monthSel: "",
  //   weekSel: "",
  //   dateSel: "",
  // });
  const dispatch = useDispatch();
  const statsData = useSelector((state) => state.user.statsData);
  const companyDataLoader = useSelector((state) => state.user.companyDataLoader)
  const clientCoursesData = useSelector((state) => state.user.clientCoursesData)
  const clientsList = useSelector((state) => state.client.allClientList)
  
  const statisticTab = useSelector((state) => state.user.statisticTab);
  const select = statisticTab?.select || {
    yearSel: "",
    monthSel: "",
    weekSel: "",
    dateSel: "",
  }
  const dateFrom = statisticTab?.dateFrom || ""
  const dateTo = statisticTab?.dateTo || ""
  const clientId = statisticTab?.clientId || 0
  const clientName = statisticTab?.clientName || ""
  const show = statisticTab?.show || false
  const textData = statisticTab?.textData || []
  // const data = statisticTab?.data || {}
  

  const currentPath = window.location.pathname;
  const containsPath = currentPath.indexOf("analytics") !== -1;
  
  const urlParams = useParams()

  useEffect(() => {
 
    const client_id = urlParams?.client || 0
    const location = urlParams?.location || 0
    if (containsPath) {
      
      // setClientId(client_id)
      dispatch(setStatisticTabData({...statisticTab, clientId: client_id}))
    } 
  }, [])
  const getCurrentDateFormatted = (date) => {
    
    const currentDate = date

    // Extract year, month, and day components
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');

    // Format the date as "yyyy/mm/dd"
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}
  let filteredData = {};
  let maxVisual = 0;
  const getMaxCount=(data,sel)=>{
    if(sel.dateSel){
       
        const { monthSel, yearSel, weekSel, dateSel } = select;
        // prettier-ignore
        const dataY = [
          "00", "01", "02", "03", "04", "05", "06", "07",
          "08", "09", "10", "11", "12", "13", "14", "15",
          "16", "17", "18", "19", "20", "21", "22", "23"
        ]
        // prettier-ignore
        const dataX = [
          "00", "01", "02", "03", "04", "05", "06", "07", "08", "09",
          "10", "11", "12", "13", "14", "15", "16", "17", "18", "19",
          "20", "21", "22", "23", "24", "25", "26", "27", "28", "29",
          "30", "31", "32", "33", "34", "35", "36", "37", "38", "39",
          "40", "41", "42", "43", "44", "45", "46", "47", "48", "49",
          "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"
        ];
        const XYStmtData={}
        const XYDataMap = [];
        const filteredDataDate = data[yearSel]["months"][monthSel]["dates"];
      
        for (let hours of dataY) {
          if (!filteredDataDate[dateSel].hours[hours]) {
            for (let i = 0; i < dataX.length; i++) {
              XYDataMap.push([Number(dataX[i]), Number(hours), 0,[]]);
            }
            continue;
          }
          for (let minutes of dataX) {
            if (filteredDataDate[dateSel].hours[hours].minutes[minutes]?.count) {
              XYDataMap.push([
                Number(minutes),
                Number(hours),
                filteredDataDate[dateSel].hours[hours].minutes[minutes]?.count,
                filteredDataDate[dateSel].hours[hours].minutes[minutes]?.data,

              ]);
            } else {
              XYDataMap.push([Number(minutes), Number(hours), 0, []]);
            }
          }
        }
        let maxSum = 0
        const reducedSeriesData = [];
        // Loop through the XYDataMap in 2x2 blocks (in chunks of 60 (rows))
        for (let k = 0; k < XYDataMap.length / 60; k += 2) {
          // Iterate through the X and Y coordinates within each 2x2 block(in chunks of 2 (columns))
      
          for (let i = k * 60; i < (k + 1) * 60; i += 2) {
            // Calculate the sum of values in the 2x2 block
            
            const concatData = [...XYDataMap[i][3], ...XYDataMap[i + 1][3], ...XYDataMap[60 + i][3], ...XYDataMap[60 + i + 1][3]] 
            const sum =
              XYDataMap[i][2] + // Top-left value
              XYDataMap[i + 1][2] + // Top-right value
              XYDataMap[60 + i][2] + // Bottom-left value
              XYDataMap[60 + i + 1][2]; // Bottom-right value
            maxSum = Math.max(sum, maxSum)
            // Calculate the new X and Y coordinates for the reduced data
            // Store the reduced data in the reducedSeriesData array
            const _X = Math.floor(XYDataMap[60 + i + 1][0] / 2)
            const _Y = Math.floor(XYDataMap[60 + i + 1][1] / 2)
            XYStmtData[`${_X}/${_Y}`] = concatData
            reducedSeriesData.push([
              _X,
              _Y,
              sum,
            ]);
          }
        }
      
        if(JSON.stringify(XYStmtData) !== JSON.stringify(pointerDate)){ 
          setPointerDate({...XYStmtData})
        }
        return maxSum
    }
    else if (sel?.weekSel){
      let week = sel?.weekSel?.split("-")[1]
     
      let start=week-1
      let end=+week
      let ans=0
     
      for(let i=start*7+1;i<=end*7;i++){

        const date =i<10? `0${i}`:i
        // data?.[sel.yearSel]?.months?.[sel.monthSel]?.dates?.[date].maxVisual
        
        ans=Math.max(ans,data?.[sel.yearSel]?.months?.[sel.monthSel]?.dates?.[date]?.maxVisual || 0)
      }
     
      return ans
    }
    else if (sel?.monthSel){
      maxVisual=data?.[sel.yearSel].months?.[sel.monthSel].maxVisual
      return maxVisual
    }
    else {
      maxVisual=data?.[sel.yearSel]?.maxVisual
      return maxVisual
    }
    }
  if (statsData?.filteredData && clientId) {
    filteredData = statsData.filteredData;
   
   maxVisual = getMaxCount(filteredData,select) || statsData.maxVisual
    // maxVisual = filteredData?.[select.yearSel].months?.[select.monthSel]?.dates?.[select.dateSel]?.couunt || filteredData?.[select.yearSel].months?.[select.monthSel]?.couunt || statsData.maxVisual;
  }
  // useEffect(() => {
  //   if (!Object.keys(statsData?.filteredData).length) {
  //     dispatch(statsDataRequest({clientid:152, dateFrom:"2021-09-02", dateTo:"2021-09-09"}));    
  //   }
  //   setData(filteredData);
  // }, []);
  function convertToAmPm(hourValue) {
    const hour = +hourValue 
    if (hour >= 1 && hour < 12) {
        return [hour, "AM"];
    } else if (hour === 0|| hour === 24) {
        return [12,"AM"];
    } else if (hour === 12) {
      return [12,"PM"];
    } else if (hour >= 13 && hour <= 23) {
        return [hour - 12,"PM"];
    }  else {
        return ["",""]; 
    }
  }
  const returnNumberSuffix = (num) => {
    try {
        const parsedNum = parseInt(num, 10);
        if (isNaN(parsedNum)) {
            throw new Error('Invalid input. Please provide a valid number.');
        }
  
        const lastTwoDigits = parsedNum % 100;
        const lastDigit = parsedNum % 10;
  
        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
            return 'th';
        }
  
        switch (lastDigit) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    } catch (error) {
        console.error(error.message);
        return '';
    }
  }
  const getStats = (select,data)=>{
if(select.dateSel){
  const year = select.yearSel;
  const month= select.monthSel;
  const date = select.dateSel;
  let array=[]
  let datesData=data?.[year]?.months?.[month]?.dates?.[date].hours
  let datesDatakeys=Object.keys(data?.[year]?.months?.[month]?.dates?.[date].hours)
  datesDatakeys.map(hour=>{
    let minuteDataKey=Object.keys(datesData[hour].minutes)
    let minuteData=datesData[hour].minutes
    minuteDataKey.map(minute=>{
      array.push([minuteData[minute].count, {year, month, date, hour, minute}])
    })
   })
 
   array.sort((a,b)=> (a[0] - b[0]))
   return [array[0],array[array.length-1],array[Math.floor(array.length/2)]]
  
}
if(select.weekSel && !select.dateSel){
  const { weekSel } = select;
  const year = select.yearSel
  const month= select.monthSel
  const weekC = weekSel.split("-")[1];
  let date2 = weekC*7
  let date1=(weekC-1)*7+1<31?(weekC-1)*7+1:""
  const weekArrstats= []
  for(let i = date1;i<=date2;i++){
    i=i<10?`0${i}`:i
    if(data[year]?.months?.[month]?.dates?.[i]){
        const dateinWeek=data[year]?.months?.[month]?.dates?.[i]

        const hoursOfSelectedDate=Object.keys(dateinWeek.hours)
        
       
        hoursOfSelectedDate.map(hours=>{
          weekArrstats.push([data[year]?.months?.[month]?.dates?.[i].hours?.[hours].count, {year, month, date:i, hour:hours}])
        })
    }
    
  }
 
  weekArrstats.sort((a,b)=> (a[0] - b[0]))
  
  return[weekArrstats[0],weekArrstats[weekArrstats.length-1],weekArrstats[Math.floor(weekArrstats.length/2)]]
 
}
if(select.monthSel && !select.weekSel && !select.dateSel){

  const array=[]
  const year = select.yearSel
  const month= select.monthSel
  const datearr=Object.keys(data[year]?.months?.[month].dates)
  
  datearr.map(date=>{
    array.push([data[year]?.months?.[month].dates?.[date].count, {year, month, date}])
  })
  
  array.sort((a,b)=> (a[0] - b[0]))
 
  return [array[0],array[array.length-1],array[Math.floor(array.length/2)]]
  
}
if(select.yearSel && !select.monthSel && !select.weekSel && !select.dateSel){
  const array=[]

  if(data[select?.yearSel]?.months){
    
    Object.keys(data[select?.yearSel].months).map(item=>{
   Object.keys(data[select?.yearSel].months?.[item].dates).map(value=>{
     
     array.push([data[select?.yearSel].months?.[item].dates?.[value]?.count, {year:select?.yearSel, month:item, date:value}])
   })
   })
  }

  array.sort((a,b)=> (a[0] - b[0]))
 
  return [array[0],array[array.length-1],array[Math.floor(array.length/2)]]
}

  }
  const getDate=(select)=>{
      if(select.dateSel){
       
        const lowest = `${convertToAmPm(textData?.[0][1]?.hour)[0]}:${textData?.[0][1]?.minute} ${convertToAmPm(textData?.[0][1]?.hour)[1]}`
        const medium = `${convertToAmPm(textData?.[2][1]?.hour)[0]}:${textData?.[2][1]?.minute} ${convertToAmPm(textData?.[2][1]?.hour)[1]}`
        const highest = `${convertToAmPm(textData?.[1][1]?.hour)[0]}:${textData?.[1][1]?.minute} ${convertToAmPm(textData?.[1][1]?.hour)[1]}`
        return [lowest, highest, medium]
      }
      else if(select.weekSel){
    
        const lowest = `${textData?.[0][1]?.date}${returnNumberSuffix(textData?.[0][1]?.date)} ${getMonthName(select.monthSel).slice(0,3)}, ${convertToAmPm(textData?.[0][1]?.hour).join("")}`
        const medium = `${textData?.[2][1]?.date}${returnNumberSuffix(textData?.[2][1]?.date)} ${getMonthName(select.monthSel).slice(0,3)}, ${convertToAmPm(textData?.[2][1]?.hour).join("")}`
        const highest = `${textData?.[1][1]?.date}${returnNumberSuffix(textData?.[1][1]?.date)} ${getMonthName(select.monthSel).slice(0,3)}, ${convertToAmPm(textData?.[1][1]?.hour).join("")}`
        return [lowest, highest, medium]
      }
      else if(select.monthSel){
        
        const lowest = `${textData?.[0][1]?.date}/${textData?.[0][1]?.month}/${textData?.[0][1]?.year}`
        const medium = `${textData?.[2][1]?.date}/${textData?.[2][1]?.month}/${textData?.[2][1]?.year}`
        const highest = `${textData?.[1][1]?.date}/${textData?.[1][1]?.month}/${textData?.[1][1]?.year}`
        return [lowest, highest, medium]
      }
      else if(select.yearSel){
    
        const lowest = `${textData?.[0]?.[1]?.date}/${textData?.[0]?.[1]?.month}/${textData?.[0]?.[1]?.year}`
        const medium = `${textData?.[2]?.[1]?.date}/${textData?.[2]?.[1]?.month}/${textData?.[2]?.[1]?.year}`
        const highest = `${textData?.[1]?.[1]?.date}/${textData?.[1]?.[1]?.month}/${textData?.[1]?.[1]?.year}`
        return [lowest, highest, medium]
      }
      return ""
  }
  const getTotalCount=(select,data)=>{
    let datacount=data[select?.yearSel]?.months
    let total=0
    if(datacount){

      Object.keys(datacount).map(item=>
        total+=datacount[item].count)
        return total
    }
    return 0
      }
 let interactionData = [
    {
      id:1,
      heading:"Total interaction in the selected year",
      counts:select.yearSel?getTotalCount(select,filteredData) : 0,
      interval:select.yearSel || "",
    },
    {
      id:2,
      heading:"Highest Interaction",
      counts:textData?.[1]?.[0] || 0,
      interval:`On ${getDate(select)?.[1] || ''}`,
    },
    {
      id:3,
      heading:"Medium Interaction",
      counts:textData?.[2]?.[0] || 0,
      interval:`On ${getDate(select)?.[2] || ''}`,
    },
    {
      id:4,
      heading:"Lowest Interaction",
      counts:textData?.[0]?.[0] || 0,
      interval:`On ${getDate(select)?.[0] || ''}`,
    },
  ]
  useEffect(()=>{
     interactionData = [
      {
        id:1,
        heading:"Total Interaction in the last year",
        counts:select.yearSel?getTotalCount(select,filteredData) : 0,
        interval:select.yearSel || "",
      },
      {
        id:2,
        heading:"Highest Interaction",
        counts:textData?.[1] || 0,
        interval:getDate(select),
      },
      {
        id:3,
        heading:"Medium Interaction",
        counts:textData?.[2] || 0,
        interval:getDate(select),
      },
      {
        id:4,
        heading:"Lowest Interaction",
        counts:textData?.[0] || 0,
        interval:getDate(select),
      },
    ]
   
  },[textData])
  
  
  useEffect(()=>{
    if(Object.keys(statsData?.filteredData).length && !isInitialRender){
      dispatch(setStatisticTabData({...statisticTab, select: {...select,yearSel:Object.keys(statsData?.filteredData)[0]}}))
      // setSelect(()=>({...select,yearSel:Object.keys(statsData?.filteredData)[0]}))
      // getStats(select,statsData?.filteredData)
    }
  },[statsData])



  let week = {}
  

  useEffect(() => {
    
    if (select.monthSel) {
      week = {}
      let arr = Object.keys(
        filteredData?.[`${select.yearSel}`]["months"][`${select.monthSel}`][
          "dates"
        ]
      );
      for (let i = 0; i < arr.length; i++) {
        const day = parseInt(arr[i], 10);
        const weekNumber = Math.ceil(day / 7);
        const weekKey = `week-${weekNumber}`;

        if (!week[weekKey]) {
          week[weekKey] = [];
        }
      }
      setWeek({...week})
    }

    if (select.dateSel && select.monthSel && select.weekSel) {
      setData(
        filteredData[`${select.yearSel}`]["months"][`${select.monthSel}`][
          "dates"
        ][`${select.dateSel}`]
        
      );
      dispatch(setStatisticTabData({...statisticTab, textData: getStats(select,filteredData)}))
      // setTextData(getStats(select,filteredData))
    } else if (select.monthSel) {
      setData(
        filteredData?.[`${select.yearSel}`]["months"][`${select.monthSel}`]
      );
      // setTextData(getStats(select,filteredData))
      dispatch(setStatisticTabData({...statisticTab, textData: getStats(select,filteredData)}))
      
    } else if (select.weekSel && select.monthSel) {
      setData(
        filteredData[`${select.yearSel}`]["months"][`${select.monthSel}`][
          "dates"
        ]
        
      );
      // setTextData(getStats(select,filteredData))
      dispatch(setStatisticTabData({...statisticTab, textData: getStats(select,filteredData)}))
    } else {
      setData(filteredData?.[`${select.yearSel}`]);
  
      // setTextData(getStats(select,filteredData))
      dispatch(setStatisticTabData({...statisticTab, textData: getStats(select,filteredData)}))
     
    }
  
    
  }, [select]);

  useEffect(() => {
    if(dateFrom && dateTo && !isInitialRender){
      const date_from = new Date(dateFrom)
      const date_to = new Date(dateTo)
      
      if(date_from < date_to){
        dispatch(userActions.selectedClientCoursesDataRequest({clientId:clientId}))
        dispatch(statsDataRequest({clientid:clientId, dateFrom: getCurrentDateFormatted(dateFrom), dateTo: getCurrentDateFormatted(dateTo)}));

        // dispatch(statsDataRequest({clientid:clientId, dateFrom, dateTo}));
        dispatch(
          setStatisticTabData({
            ...statisticTab,
            select: {
              yearSel: "",
              monthSel: "",
              weekSel: "",
              dateSel: "",
            },
          })
        );
        
      }
      else{
        addToast(`'From' date should be less than 'to' date`, {
          appearance: "error",
          autoDismiss: true,
          placement: "top-right",
        });
      }
      

    }
    else{
      setIsInitialRender(false)
    }
  }, [dateFrom, dateTo])
  
  const onClietSelectChange = (e) => {
    const [clientid, clientname] = e.target?.value?.split("-")
    // setClientId(Number(clientid))
    // setClientName(clientname)
    // setDateFrom("")
    //   setDateTo("")
    dispatch(
      setStatisticTabData({
        ...statisticTab,
        clientId: clientid,
        clientName: clientname,
        dateFrom: "",
        dateTo: "",
        show: false,
        select: {
          yearSel: "",
          monthSel: "",
          weekSel: "",
          dateSel: "",
        },
      })
    );
      
      setWeek({})
      // setShow(false)
      dispatch(statsDataRequest({}));
  }
  
  ///////////////////////////////



  const userInteractionGraphTitle = (select, clientname) => {
    const defaultValue = "Users Interaction graph"
    if(select?.dateSel.length !== 0){
      
      const formattedDate = `${select.dateSel}${returnNumberSuffix(select.dateSel)} ${getMonthName(select.monthSel)} ${select.yearSel}`
        return `${clientname} ${defaultValue} - ${formattedDate}`
    }
    else if(select?.weekSel.length !== 0){
     const weekNum = select.weekSel.split("-")[1]
      const formattedDate = `${weekNum}${returnNumberSuffix(weekNum)} Week of ${getMonthName(select.monthSel)} ${select.yearSel}`;
      return `${clientname} ${defaultValue} - ${formattedDate}`
    }
    else if(select?.monthSel.length !== 0){
      const formattedDate = `${getMonthName(select.monthSel)} ${select.yearSel}`
      return `${clientname} ${defaultValue} - ${formattedDate}`
    }
    else if(select?.yearSel.length !== 0){
      const formattedDate = `${select.yearSel}`
      return `${clientname} ${defaultValue} - ${formattedDate}`
    }
    else {
      return defaultValue
    } 
  }

  const weekNum = Number(select?.weekSel.split("-")[1])
  

const onFromDateSelect = (date) => {
  // setDateFrom(date)
  dispatch(setStatisticTabData({...statisticTab, dateFrom: date}))
}
const onToDateSelect = (date) => {
  // setDateTo(date)
  dispatch(setStatisticTabData({...statisticTab, dateTo:date, show:true}))
  // setShow(true)
}
function getMonthName(monthNumber) {
  // array of month names
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Validating the month number
  if (monthNumber < 1 || monthNumber > 12) {
    throw new Error("Invalid month number. Month number must be between 1 and 12.");
  }

  // Return the corresponding month name
  return monthNames[monthNumber - 1];
}
useEffect(() => {
  if(collapsed){
    if(interactionGraphRef?.current?.clientHeight){
      setHeightInteractionCont(graphHeaderRef?.current?.getBoundingClientRect().height)
    }

  }
  else{

    if(interactionGraphRef?.current?.clientHeight){
      setHeightInteractionCont(interactionGraphRef?.current?.clientHeight + graphHeaderRef?.current?.getBoundingClientRect().height)
    }
  }
}, [interactionGraphRef?.current?.clientHeight])

const onClickCollapseSwitch = (e) => {
  setCollapsed(!collapsed)
  if(interactionGraphRef?.current?.clientHeight && !collapsed){
    setHeightInteraction(interactionGraphRef?.current?.clientHeight)
    setHeightInteractionCont(graphHeaderRef?.current?.getBoundingClientRect().height) 
  }
  else{
    setHeightInteraction(0)
    setHeightInteractionCont(interactionGraphRef?.current?.clientHeight + graphHeaderRef?.current?.getBoundingClientRect().height) 
  }
}
const handleClickCell = (UserInteraction, pointerDate) => (opts1,opts2) => {
  
  setMinuteData(pointerDate?.[`${opts1.data[0]}/${opts1.data[1]}`])
  dispatch(utilityActions.toggleSideSheet({
    width: 700,
    direction: 'right',
    title: "User Interaction",
    open: true,
    component: <UserInteraction pointerDate={pointerDate} opts1={opts1} opts2={opts2}/>
}))
}


const sortClientList = (list) => {
  const sortedList = [...list]?.sort((a, b) => a.clientname.localeCompare(b.clientname))
  return sortedList
}

  return (
    <>
    {containsPath && <SideSheet />}
    <div className="statistics-wrapper">
    <div className="statistics-tag-container">
      <div className={`tag-header ${containsPath?'header-align':''}`}>
        <p className="heading-text">Learner Analytics</p>
      </div>
      <Box className="tag-box">
        <div className="stats-selections-wrapper d-flex gap-5">
      <div className="client-selection">
      {containsPath?<p>Select a <strong>date range</strong> to view the visualization.</p>:<p>Select a <strong>client</strong> and <strong>date range</strong> to view the visualization.</p>}
      
      <div className="stats-client-date-range">
      {!containsPath && 
      <div className="stats-selection client ">
      <CommonSelect  onChange={onClietSelectChange} data={sortClientList(clientsList)} first="" flag={true} selected={clientId}/>
      </div>
      }

      {clientId !== 0 && <>
        <div className="stats-selection date-from">
        <DatePicker
        showIcon
         selected={dateFrom}
         dateFormat="yyyy/MM/dd"
         maxDate={new Date()}
        onSelect={onFromDateSelect} //when day is clicked
         showYearDropdown
         showMonthDropdown
         scrollableYearDropdown
         inputProps={{label:`From`}}
        />
        </div>
        <div className="stats-selection  date-to">
        <DatePicker
        showIcon
         selected={dateTo}
         dateFormat="yyyy/MM/dd"  
         minDate={dateFrom}
         maxDate={new Date()}
        onSelect={onToDateSelect} //when day is clicked
         showYearDropdown
         showMonthDropdown
         scrollableYearDropdown
         inputProps={{label:`To`}}
        />

        </div>
      </>}
      </div>
      </div>
      <div className="graph-selection">
      {Object.keys(filteredData).length !== 0 && !companyDataLoader && show && (
            <>
            {/* <hr/> */}
            <p>Interact visualization by <strong>year</strong>, <strong>month</strong>, <strong>week</strong>, and <strong>date</strong> selection</p>
            <div className="graph-selection-grid">
              <div className="stats-selection">
              {/* <div className="col-xxl-4 col-lg-4 col-6 d-flex justify-content-start  flex-shrink-0 align-items-center"> */}
              <CommonSelect name="year" 
              onChange={(e) =>{
                dispatch(setStatisticTabData({...statisticTab, select:{ yearSel: `${e.target.value}`, monthSel:"", weekSel:"", dateSel:"" }}))
                // setSelect({ yearSel: `${e.target.value}`, monthSel:"", weekSel:"", dateSel:"" })
                if(monthRef?.current?.value){
                  monthRef.current.value = "";
                }
            
              }

                  
                  }
                  selected={select?.yearSel||""} 
                  first={Object.keys(filteredData)?.[0]} 
                  data={Object.keys(filteredData)?.slice(1)} 
                  text="Select Year" 
                  flag={false}
                  />
               
              </div>
              {select.yearSel ? (
                <div className="stats-selection">
                  {/* <label className="pe-2" htmlFor="month-select">Month</label> */}
                  <div className="d-flex align-items-center">
                 
                  <CommonSelect referance={monthRef} name="month"
                    selected={select?.monthSel||""}
                    onChange={(e) => {
                      if (e.target.value == "") {
                        dispatch(
                          setStatisticTabData({
                            ...statisticTab,
                            select: {
                              ...select,
                              monthSel: `${e.target.value}`,
                              weekSel: "",
                              dateSel: "",
                            },
                          })
                        );
                        // setSelect({
                        //   ...select,
                        //   monthSel: `${e.target.value}`,
                        //   weekSel: "",
                        //   dateSel: "",
                        // });
                      } else {
                        if(weekRef?.current?.value){
                          weekRef.current.value = "";
                        }
                        dispatch(setStatisticTabData({...statisticTab, select:{ ...select, monthSel: `${e.target.value}`, weekSel:"", dateSel:"" }}))
                        // setSelect({ ...select, monthSel: `${e.target.value}`, weekSel:"", dateSel:"" });
                      }
                    }}
                    flag={false}
                    data={Object.entries(
                      filteredData?.[`${select.yearSel}`]["months"]
                    ).sort((a,b)=> (+a[0]) - (+b[0]))}
                      text={"Month"}
                      type={"month"}
                      getMonthName={getMonthName}
                    ></CommonSelect>
                  
                    <span className="remove-graph-icon" ><i class="fa fa-trash" aria-hidden="true" onClick={() => {
                      monthRef.current.value = "";
                      dispatch(
                        setStatisticTabData({
                          ...statisticTab,
                          select: {
                            ...select,
                            monthSel: "",
                            weekSel: "",
                            dateSel: "",
                          },
                        })
                      );
                      // setSelect({
                      //   ...select,
                      //   monthSel: "",
                      //   weekSel: "",
                      //   dateSel: "",
                      // });
                    }}></i></span>
                  
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {select.monthSel ? (
                <div className="stats-selection">
                  {/* <label className="pe-2" htmlFor="week-select">Week</label> */}
                  <div className="d-flex align-items-center ">
                    <CommonSelect referance={weekRef} name="week"
                    selected={select?.weekSel||""}
                     onChange={(e) => {
                      if(dateRef.current?.value)
                          dateRef.current.value=""
                      if (e.target.value == "") {
                        dispatch(setStatisticTabData({...statisticTab, select:{ ...select, weekSel: "", dateSel: "" }}))
                        // setSelect({ ...select, weekSel: "", dateSel: "" });
                      } else {
                        dispatch(setStatisticTabData({...statisticTab, select:{ ...select, weekSel: `${e.target.value}`, dateSel:"" }}))
                        // setSelect({ ...select, weekSel: `${e.target.value}`, dateSel:"" });
                      }
                    }}
                    flag={false}
                    data={Object.keys(weeks)
                      .sort((a, b) => {
                        const weekNumberA = parseInt(a.match(/\d+/)[0]);
                        const weekNumberB = parseInt(b.match(/\d+/)[0]);
                        return weekNumberA - weekNumberB;
                      })}
                      text={"Week"}
                    ></CommonSelect>
                 
                  
                    
                  
                    <span className="remove-graph-icon" ><i class="fa fa-trash" aria-hidden="true" onClick={() => {
                      weekRef.current.value = "";
                      dispatch(setStatisticTabData({...statisticTab, select:{ ...select, weekSel: "", dateSel: "" }}))
                      // setSelect({ ...select, weekSel: "", dateSel: "" });
                    }}></i></span>
                
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {select.weekSel && select.monthSel ? (
                <div className="stats-selection">
                  <div className="d-flex align-items-center">
                  <CommonSelect referance={dateRef} name="date"
                  selected={select?.dateSel||""}
                     onChange={(e) =>{
                      dispatch(setStatisticTabData({...statisticTab, select:{ ...select, dateSel: `${e.target.value}` }}))
                      //  setSelect({ ...select, dateSel: `${e.target.value}` })
                     }
                    }
                    flag={false}
                    data={Object.entries(
                      filteredData[`${select.yearSel}`]["months"][
                        `${select.monthSel}`
                      ]["dates"]
                    ).sort((a,b)=> (+a[0]) - (+b[0]))}
                      text={"Date"}
                      type={"date"}
                      weekNum={weekNum}
                    ></CommonSelect>
                  
                  
                    <span className="remove-graph-icon"><i class="fa fa-trash" aria-hidden="true" onClick={() => {
                      dateRef.current.value = "";
                      dispatch(setStatisticTabData({...statisticTab, select:{ ...select, dateSel: "" }}))
                      // setSelect({ ...select, dateSel: "" });
                    }}></i></span>
                 
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              
              </div>
              
            </>
          )}
      </div>
        </div>
        {/* <div className="user-select ml-5 d-flex list-items custom-col-gap row justify-content-start justify-content-lg-start"> */}
        <div>
          {
            companyDataLoader &&  <div className="loader-wrapper">
            
              <div className="loader-cnt"> 
                <i class="fa fa-spinner" aria-hidden="true"></i>
                Loading
              </div>
          
          </div>
}
{Object.entries(filteredData).length !== 0 && !companyDataLoader ?
<>
              
  <div style={{height:heightInteractionCont?heightInteractionCont:'auto'}} className="user-interaction-graph">
    <div className="graph-header">
  <h4 ref={graphHeaderRef}>{userInteractionGraphTitle(select,clientName)}</h4>
    <div onClick={onClickCollapseSwitch} className="collapse-switch" id="collapse-heatmap-switch">{collapsed?'Expand':'Collapse'}</div>
    </div>
  <div ref={interactionGraphRef} style={{top: `-${heightInteraction}px`}} className="user-interaction-collapsible-wrapper">

  <div className={`chart-container ${select.weekSel.length!==0 || select.dateSel.length!==0 ? 'cartesian-heatmap-height' : 'calender-heatmap-height'}`}>
<StatisticsChartRender
  data={filteredData}
  dataNew={data}
  select={select}
  maxVisual={getMaxCount(filteredData,select) || statsData.maxVisual}
  pointerDate={pointerDate}
  getStats={getStats}
  // setText={(data)=>setTextData(data)}
  setText={(data)=>dispatch(setStatisticTabData({...statisticTab, textData: data}))}
  handleClickCell={handleClickCell}
/>
</div>
<div className="graph-stats">
  {interactionData?.length!==0 && interactionData?.map((item, i) => {
    return <div key={item.id} className="stats">
      <p className="title">{item.heading}</p>
      <p className="stat-data">{item.counts}</p>
      <p className="interval">{item.interval}</p>
    </div>
  })}
</div>
  </div>
</div>
<div className="pt-5" id="statistics-info-charts">
          <StatisticsInfoChartsRender select={select} filteredData={filteredData} minuteData={minuteData} clientId={clientId} clientCoursesData={clientCoursesData}/>
</div>
</> 
:
<>
{ !companyDataLoader && clientId!==0 && dateFrom && dateTo && <div className="no-data-wrapper statistics-no-data">
      <img
      src="https://assets.contentenablers.com/storefront/imgs/aml/aml_search.png"
      width={250}
      />
      <p className="no-client-data fs-4 mb-0 fw-medium"> Either this client or given date range has no data  
            </p>
      </div> }
</>
}        
        
        </div>

        
      </Box>
    </div>
    </div>
    </>
  );
};

export default Statistics;
