import { put, takeLatest, all } from "redux-saga/effects";
import APIConstant from "../../utility/APIConstant";
import * as types from "../reducers/types";
import * as userActions from "../actions/userActions";
import * as clientActions from "../actions/clientActions";
import CryptoJs from "crypto-js";

function* addRLMUser() {
  const data = arguments[0];
  let clientId = data.payload.client_id;
  let curClientId = APIConstant.getUrl("clientid");

  if (Number(data.payload.role) === 5) {
    clientId = String(clientId).split(":");
    curClientId = clientId[1];
    data.payload.client_id = clientId[0];
  } else {
    curClientId = APIConstant.getUrl("clientid");
  }
  /**
   * In CMS always send as a learner
   * Any role apart from adding a Client
   * The curator client id will be 41 ( CE Common Imp )
   */
  const encrypted = CryptoJs.enc.Base64.stringify(
    CryptoJs.enc.Utf8.parse(
      JSON.stringify({
        email: data.payload.email,
        first_name: data.payload.firstname,
        last_name: data.payload.lastname,
        password: encryptStr(data.payload.password),
        role_id: 4,
        client_id: curClientId,
      })
    )
  );

  console.log(data.payload.client_id, curClientId);

  const user_endpoint = `${getHost()}clients/addrlmuser`;
  const celms_user_add = `${APIConstant.getUrl(
    "capi"
  )}rpl/rpl_clonecourses/addPreviewUser?data=${encrypted}`;

  const postData = {
    method: "POST",
    headers: getAuthHeader(data?.payload),
    body: JSON.stringify(data.payload),
  };
  const clmsUserPostData = {
    method: "GET",
  };

  const userResponse = yield fetch(user_endpoint, postData).then((response) =>
    response.json()
  );
  const celmsUserResp = yield fetch(celms_user_add, clmsUserPostData).then(
    (response) => response.json()
  );
  yield put(userActions.createdUserInfo(userResponse));
}

function getHost() {
  // const apihost = APIConstant.API_DESTINATION
  const apihost = window.location.href.includes("localhost")
    ? APIConstant.getUrl("api")
    : APIConstant.getUrl("api");
  return apihost;
}

function enryptCredentials(crds) {
  var iv = CryptoJs.enc.Utf8.parse(APIConstant.ENCRYPT_IV); //random number for encryption algorithm
  var key = CryptoJs.enc.Utf8.parse(APIConstant.ENCRYPT_KEY); //secret key for encryption algorithm
  let encryptedValue = CryptoJs.AES.encrypt(crds, key, {
    iv: iv,
    mode: CryptoJs.mode.CBC,
    padding: CryptoJs.pad.Pkcs7,
  });
  return encodeURIComponent(encryptedValue.toString());
}

function encryptStr(str) {
  const key = CryptoJs.enc.Utf8.parse(APIConstant.ENCRYPT_KEY);
  const iv = CryptoJs.enc.Utf8.parse(APIConstant.ENCRYPT_IV);
  var _enid = CryptoJs.AES.encrypt(str, key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJs.mode.CBC,
    padding: CryptoJs.pad.Pkcs7,
  });
  return _enid.toString();
}

function* rlmUserLogin() {
  const data = arguments[0];

  const user_endpoint = `${getHost()}clients/login`;
  const postData = {
    method: "POST",
    headers: getAuthHeader(data?.payload),
  };
  const userResponse = yield fetch(user_endpoint, postData).then((response) =>
    response.json()
  );
  if(userResponse.status==="failure"){
    yield put(userActions.directLoginFail(true))
  }
  else{
    userActions.directLoginFail(true)
  }
  

  yield put(userActions.loggedInUserInfo(userResponse));
}

function* getUserAssignedCourses() {
  const data = arguments[0];
  if (data?.user && data?.user?.role !== -1) {
    const user_courses = `${getHost()}learning_aids/getassignedcourses/${
      data?.user?._id
    }`;
    const rdata = {
      method: "GET",
      headers: getAuthHeader(data?.payload),
    };
    const courses = yield fetch(user_courses, rdata).then((response) =>
      response.json()
    );
    yield put(userActions.assignedCoursesResponse(courses));
  }
}

function* courseAssignment() {
  const data = arguments[0];
  const courses_assign = `${getHost()}learning_aids/assigncourses/`;
  const rdata = {
    method: "POST",
    headers: getAuthHeader(data?.payload),
    body: JSON.stringify(data.payload),
  };
  const assined = yield fetch(courses_assign, rdata).then((response) =>
    response.json()
  );
  yield put(
    userActions.setAssignedCourses({ res: assined, req: data.payload })
  );
}

function* userCourseAssignment() {
  console.log("inside api call");
  const data = arguments[0];
  const courses_assign = `${getHost()}learning_aids/assignMultipleUsers`;
  const rdata = {
    method: "POST",
    headers: getAuthHeader(data?.payload),
    body: JSON.stringify(data.payload),
  };
  const users = yield fetch(courses_assign, rdata).then((response) =>
    response.json()
  );
  yield put(userActions.allUsersResponse(users));
}

function* courseAssign() {
  const data = arguments[0];
  const courses_assign = `${getHost()}learning_aids/assignMultipleUsers`;
  const rdata = {
    method: "POST",
    headers: getAuthHeader(data?.payload),
    body: JSON.stringify(data.payload),
  };
  const users = yield fetch(courses_assign, rdata).then((response) =>
    response.json()
  );
  yield put(userActions.allUsersResponse(users));
}

function* getAllCourses() {
  const data = arguments[0];
  const all_courses = `${getHost()}courses/getcourses`;
  const rdata = {
    method: "GET",
    headers: getAuthHeader(data?.payload),
  };
  const allcourses = yield fetch(all_courses, rdata).then((response) =>
    response.json()
  );
  yield put(userActions.allCoursesResponse(allcourses));
}

function* getAllUsers() {
  const data = arguments[0];
  const all_courses = `${getHost()}clients/listallusers`;
  const rdata = {
    method: "GET",
    headers: getAuthHeader(data?.payload),
  };
  const users = yield fetch(all_courses, rdata).then((response) =>
    response.json()
  );
  yield put(userActions.allUsersResponse(users));
}

function getAuthToken(data) {
  return window.btoa(data?.email + ":" + data?.password);
}

function getAuthHeader(payload) {
  return {
    "Content-Type": "application/json",
    Authorization: "Authorization:Bearer " + getAuthToken(payload),
  };
}

// function* lmsAutoLogin(){
//     // https://appproxy.contentenablers.com/curator/index.php/api/courses/lmsrlm_autologin
//     // https://appproxy.contentenablers.com/curator/index.php/api/courses/getCelmsCourseid/id/"+courseid,
// }

function* getAdminAcl() {
  const data = arguments[0];
  const acl_path = `${getHost()}learning_aids/getacl`;
  const rdata = {
    method: "GET",
    headers: getAuthHeader(data?.payload),
  };
  const aclList = yield fetch(acl_path, rdata).then((response) =>
    response.json()
  );
  yield put(userActions.adminAclResponse(aclList));
}

function* addAcl() {
  const data = arguments[0];
  const acl_assign = `${getHost()}learning_aids/addacl/`;

  console.debug("data", data, "acl_assign", acl_assign);
  // const rdata = {
  //     method: 'POST',
  //     headers: getAuthHeader( data?.payload ),
  //     body: JSON.stringify( data.payload )
  // }
  // const assined = yield fetch( acl_assign, rdata ).then( response => response.json() );
  // yield put( userActions.setAssignedCourses( { res: assined, req: data.payload } ) );
}

function* updateAcl() {
  const data = arguments[0];
  const update_endpoint = `${getHost()}learning_aids/updateacl/`;
  console.debug("data", data);
  const rdata = {
    method: "POST",
    headers: getAuthHeader(data?.payload),
    body: JSON.stringify(data.payload),
  };
  const assined = yield fetch(update_endpoint, rdata).then((response) =>
    response.json()
  );
  yield put(userActions.updateAclResponse(assined));
}

/** API Response from curator */
function* getAllClients() {
  const data = arguments[0];
  // const clients_path = `https://curator.contentenablers.com/index.php/api/clients/clientslist`;
  const clients_path = `${getHost()}clients/getClients`;
  const obj = {
    method: "GET",
    headers: getAuthHeader(data?.payload),
  };
  const clientList = yield fetch(clients_path, obj).then((response) =>
    response.json()
  );

  yield put(userActions.associateClients(clientList));
  yield put(clientActions.getAllClientResponse(clientList));
}

/** LoggedIn client courses  client role id refer CONSTANT*/
function* getLoggedInClientCourses() {
  const data = arguments[0];
  const clients_crs = `${getHost()}courses/getclientcourses/${data?.payload}`;
  const crdata = {
    method: "GET",
    headers: getAuthHeader(data?.payload),
  };
  const crsList = yield fetch(clients_crs, crdata).then((response) =>
    response.json()
  );
  
  // to get config couraea information
  if(crsList?.data?.configs.length>0){
    let ids=[]
    crsList?.data?.configs.forEach(item=>ids.push(item._id))
    
    let body={
      "ids":ids
    }
    const apihost = `${APIConstant.getUrl('capi')}predeployconfig/getcelmscourses`
    const headers = {
      "Content-Type": "application/json",
    };
    const apiCreds = {
      method: "POST",
      headers:headers,
      body: JSON.stringify(body)
    };
    const res = yield fetch(apihost, apiCreds).then((responce) => responce.json()).catch((e) => console.log(e))
    
    yield put(clientActions.getClientCoursesResponse(crsList));
    yield put(userActions.setSelectedClientDataRes(res?.data.filter(item=>item.player_version_id==="20") || []))
    
  }

}

function* createScormPackage() {
  const data = arguments[0];
  const scorm_crs = `https://api.ceauthor.com/api/courseconfig/getscormpackage/${data?.payload}/4`;
  const crdata = {
    method: "GET",
    // headers: getAuthHeader( data?.payload )
  };
  const scormlist = yield fetch(scorm_crs, crdata).then((response) =>
    response.json()
  );
  yield put(userActions.scormPackaged(scormlist));
}

/** Course selected after supplimentary list*/
function* getSupplimentary() {
  const data = arguments[0];
  const all_supplimentary_get = `${getHost()}learning_aids/getcoursesupplimentaries/${
    data?.payload.course._id
  }`;
  const getdata = {
    method: "GET",
    headers: getAuthHeader(data?.payload),
  };
  const supplimentarys = yield fetch(all_supplimentary_get, getdata).then(
    (response) => response.json()
  );
  yield put(userActions.allSupplimentaryResponse(supplimentarys));
}

function* addSupplimentary() {
  const data = arguments[0];
  const supplimentary_add = `${getHost()}learning_aids/addsupplimentary`;
  const postData = {
    method: "POST",
    headers: getAuthHeader(data?.payload),
    body: JSON.stringify(data?.payload),
  };
  const supplimentary = yield fetch(supplimentary_add, postData).then(
    (response) => response.json()
  );
  yield put(userActions.addSupplimentaryResponse(supplimentary));
}

function* updateSupplimentary() {
  const data = arguments[0];
  const supplimentary_add = `${getHost()}learning_aids/updatesupplimentary`;
  const updateData = {
    method: "POST",
    headers: getAuthHeader(data?.payload),
    body: JSON.stringify(data?.payload),
  };
  const result = yield fetch(supplimentary_add, updateData).then((response) =>
    response.json()
  );
  yield put(userActions.updateSupplimentaryResponse(data?.payload, result));
}

/** Course selected after history list*/
function* getHistory() {
  const data = arguments[0];
  const configId = data?.payload?.course?._id || "6171396a1ff979024daafafc";
  if (data?.payload.allCourseStructure[configId]) {
    yield put(
      userActions.selectedHistory(data?.payload.allCourseStructure[configId])
    );
  } else {
    const history_path = APIConstant.getUrl("configstructure");
    const course_history_get = `${history_path}${configId} ${
      APIConstant.DEPLOY_ENV === "prod" ? "" : "/1"
    }`;
    const getdata = { method: "GET", headers: getAuthHeader(data?.payload) };
    const historys = yield fetch(course_history_get, getdata).then((response) =>
      response.json()
    );
    yield put(
      userActions.allHistoryResponse({
        ...historys,
        _id: data?.payload?.course?._id,
      })
    );
  }
}

const adminTagsHost = APIConstant?.ADMIN_TAGS_URL;

/* Fetching All Admin Tags */
function* getAdminTags() {
  const data = arguments[0];
  const tagPath = `${adminTagsHost}ce-assets-json`;
  const getdata = {
    method: "GET",
    headers: getAuthHeader(data?.payload),
  };
  const response = yield fetch(tagPath, getdata).then((response) =>
    response.json()
  );
  let adminTags = [];
  let isTag = [];
  let externalIdx = -1;
  if (response) {
    const tagList = response?.data?.attributes?.assetmgr_config?.keywords.map(
      (keys, index) => {
        if (keys.topic === "External Tags") {
          externalIdx = index;
        }
        return keys.tags.map((aTag) => {
          if (isTag.indexOf(aTag?.trim()) === -1) {
            adminTags.push({ tag: aTag, pIdx: index });
            isTag.push(aTag?.trim());
          }
          return aTag;
        });
      }
    );
  }
  yield put(
    userActions.adminTagsResponse({
      data: response.data?.attributes,
      adminTags: adminTags,
      externalIdx: externalIdx,
    })
  );
}

/* Updating  Admin Tags */
function* updateAdminTags() {
  const data = arguments[0];
  const adminTagsPath = `${adminTagsHost}ce-assets-json/updateJson`;
  const updateTags = {
    method: "POST",
    headers: getAuthHeader(data?.payload),
    body: JSON.stringify(data?.payload),
  };
  const result = yield fetch(adminTagsPath, updateTags).then((response) =>
    response.json()
  );
}

/**
 * If multiple client are there, logging in only one client
 * For multiple clients, show the dropdonw in UI and select the client
 * The the api return has to be stored in model
 */
function* getLmsAuthToken() {
  const userInfo = arguments[0].payload;
  const signinlms = `${APIConstant.getUrl("lms")}celmsmicroserviceaccess/`;

  let headers = {
    "Content-Type": "application/json",
    Authorization: getAuthToken(userInfo),
  };

  try {
    if (userInfo.email && userInfo.password) {
      const clnId =
        userInfo.clients.length === 0
          ? APIConstant.getUrl("clientid")
          : userInfo.clients[0]?.clientid;
      const password = window.atob(window.sessionStorage.getItem("p")); //CryptoJs.enc.Base64.stringify(userInfo.crd);
      const splemail = String(userInfo.email).split("@");
      const emailId = `${splemail[0]}_ceauthor@${splemail[1]}`;
      const enctext = `${emailId}^${password}^${clnId}`;

      const encrypted = enryptCredentials(enctext);
      headers.Authorization = encrypted;
    }
  } catch (e) {
    console.log("Error in enryption");
    console.log("Exception  ", e);
  }

  const lmssigninInfo = {
    method: "GET",
    headers: headers,
  };

  try {
    console.log(lmssigninInfo);
    const lmsAuthRes = yield fetch(signinlms, lmssigninInfo).then((response) =>
      response.json()
    );
    console.log(lmsAuthRes);
    yield put(userActions.setLmsSignedUrl(lmsAuthRes));
  } catch (e) {
    console.log("Error  ", e);
  }
}

function* getStatsData() {
    const {clientid, dateFrom, dateTo} = arguments[0].payload;
    if(clientid){
      const apihost = `${APIConstant.getUrl('ceapi')}statements/getstatementsbydate/${clientid}/${dateFrom}/${dateTo}`
   
    const apiHeader = {
      method: "GET",
    };
    yield put(userActions.setCompanyLoader(true));
    const companyStats = yield fetch(apihost, apiHeader)
      .then((respnse) => respnse.json())
      .catch((e) => console.log(e));
    console.log(companyStats)
    
    const filteredData = processCompanyStats(companyStats);
    let maxVisual = 0;
    yield put(
      userActions.statsDataResponse({
        filteredData,
        maxVisual,
      })
    );
    yield put(userActions.setCompanyLoader(false));
    }
    else{
      yield put(
        userActions.statsDataResponse({
          filteredData: {},
          maxVisual: null,
        })
      );
    }
   
}
function* getCompanyNames() {
  // const name = arguments[0].payload;

  const apihost = `${getHost()}aml/listamlprocessprogress/1`;
  const cmpdata = {
    method: "GET",
  };
  const companyNames = yield fetch(apihost, cmpdata)
    .then((respnse) => respnse.json())
    .catch((e) => console.log(e));
  if (companyNames?.data?.length !== 0) {
   
    yield put(userActions.companyDataResponse(companyNames?.data));
  } else {
    yield put(userActions.companyDataResponse([]));
  }
}

function* getSelectedCompanyData() {
  const id = arguments[0].payload;

  const apihost = `${getHost()}aml/getamlprocessprogress/${id}/3`
  const apiSeat = `${getHost()}cegeneral/getseatconfiguration`
  
  const cmpdata = {
    method: "GET",
  };
  const body = {
    lmsid: id,
    type: 6,
  };
  const headers = {
    "Content-Type": "application/json",
  };
  const seat = {
    method: "POST",
    body: JSON.stringify(body),
    headers: headers,
  };
  if (id?.length) {
    const companyData = yield fetch(apihost, cmpdata)
    .then((response) => response.json())
    .catch((e) => console.log(e));
    const seatData = yield fetch(apiSeat, seat)
    .then((response) => response.json())
    .catch((e) => console.log(e));

    if (Array.isArray(companyData?.data)) {
      yield put(userActions.selectedCompanyDataResponse(companyData?.data));
      yield put(userActions.selectedCompanyDataSeats(seatData?.data));
      yield put(userActions.setCompanyLoader(false));
    } else {
      yield put(userActions.selectedCompanyDataResponse([]));
      yield put(userActions.setCompanyLoader(false));
    }
  } else {
    yield put(userActions.selectedCompanyDataResponse([]));
    yield put(userActions.setCompanyLoader(false));
  }
}



function* getSelectedClientData() {
  const { clientId,locationId="0", userId="0",page, courseId="0", topicId="0",lrnId = "0", lrnType="0",clientData} = arguments[0].payload;
  yield put(userActions.setCompanyLoader(true));
    yield put(userActions.setClientUserLoader(true));
 
  const apihost = `${APIConstant.getUrl('ceapi')}statements/getstatements/${clientId}/${locationId}/${userId}/${courseId}/${topicId}/${lrnType}/${lrnId}/${page}/100`

  const apiCreds = {
    method: "GET",
  };

  const data = yield fetch(apihost, apiCreds).then((responce) => responce.json()).catch((e) => console.log(e))
  
 

  if(data?.data?.statements?.length){
    
    if(clientData[`${clientId}/${locationId}/${userId}/${courseId}/${topicId}/${lrnType}/${lrnId}`]){
      let userData = `${clientId}/${locationId}/${userId}/${courseId}/${topicId}/${lrnType}/${lrnId}`
      
        clientData[userData]={
         data:[...clientData[userData].data,...data.data?.statements],
          page:page+1,
          fetch:true
        }
    
      
    }else{
      clientData[`${clientId}/${locationId}/${userId}/${courseId}/${topicId}/${lrnType}/${lrnId}`]={
        data:data?.data?.statements,
        page:2,
        fetch:true
      }
    }
    yield put(userActions.selectedClientDataResponse(clientData))
    yield put(userActions.setCompanyLoader(false));
    yield put(userActions.setClientUserLoader(false));
  }
  else{
    if(clientData[`${clientId}/${locationId}/${userId}/${courseId}/${topicId}/${lrnType}/${lrnId}`]){
      let userData = `${clientId}/${locationId}/${userId}/${courseId}/${topicId}/${lrnType}/${lrnId}`
      
        clientData[userData]={
         ...clientData[userData],
          fetch:false
        }
    
      // change 
        yield put(userActions.selectedClientDataResponse(clientData))
      }
      yield put(userActions.setCompanyLoader(false));
      yield put(userActions.setClientUserLoader(false));
  }
  // else{
  //   yield put(userActions.selectedClientDataResponse({ }))
  // }

}

function* getCoursesCategory(){
  const apihost = `${APIConstant.getUrl('api')}courses/getcoursecategory`;
  const apiCreds = {
    method: "GET",
  };
  const data = yield fetch(apihost, apiCreds).then((responce) => responce.json()).catch((e) => console.log(e))
  if(data?.data?.length){
    yield put(userActions.courseCategoryResponse(data?.data))
  }

}
function* getUserCache(){
  const apihost = `${APIConstant.getUrl('betacache')}cache/getusercache`;
  
  const apiCreds = {
    method: "GET",
  };
  const data = yield fetch(apihost, apiCreds).then((responce) => responce.json()).catch((e) => console.log(e))
  if(data?.data?.length){
    yield put(userActions.userCacheResponse(data?.data))
  }
}
function* activeUserCache(){
  const apihost = `${APIConstant.getUrl('betacache')}statements/getactiveusers`;
  
  const apiCreds = {
    method: "GET",
  };
  const data = yield fetch(apihost, apiCreds).then((responce) => responce.json()).catch((e) => console.log(e))
  if(data?.data?.length){
    yield put(userActions.activeUserCacheResponse(data?.data))
  }
}
const setplatformScrom=(isConf,isPub,mode,user,cr,activeClient)=>{
  var json = window.btoa(JSON.stringify({
    "platform_scorm": { 'userId': user._id, 'cid': cr?._id,"isconfigured":isConf,"ispublished":isPub },
    "ceuser": {
        "uid": user._id, "firstname": user.firstname, "lastname": user.lastname, 'email': user.email, "pvtid": activeClient || 0,
        "role_id": user.role, "type": mode
    }, "cemodule": { "course_Id": cr?._id, "coursename": cr?.coursename }
}));
window.open(`${APIConstant.getUrl(Number(cr?.uisettings?.version) === 30?'ceauthor_v30':'ceauthor')}?data=${json}`);
}

function* getCoursesConfig(){
  const {courseId,mode,user,cr,activeClient} = arguments[0].payload;
  const courseid = courseId 
  const apihost = `${APIConstant.getUrl('api')}courseversion/getactiveclients/${courseid}`;
  const apihost2=`${APIConstant.getUrl('api')}courseversion/getpublishedcourse/${courseid}`;
  const apiCreds = {
    method: "GET",
  };  

    const data = yield fetch(apihost, apiCreds).then((responce) => responce.json()).catch((e) => console.log(e))
    const data2 = yield fetch(apihost2, apiCreds).then((responce) => responce.json()).catch((e) => console.log(e))
    
      // yield put(userActions.courseCategoryResponse(data?.data))
      // console.log(data2)
      let isconf=false
      let isPub=true
      if(data?.data?.config_ids?.length > 0 || data?.data?.user_ids?.length > 0 ){
        isconf=true;
      }
      if(data2?.data?.length>0){
        isPub=false
      }   
      setplatformScrom(isconf,isPub,mode,user,cr,activeClient)
  
}

function* getScromPackage(){
  const {configId}=arguments[0].payload;
  yield put(userActions.setScromLoader(true))
 const data={
    api_url: `${APIConstant.getUrl('courseconfigapi')}courseconfig/getscormpackagev2/${configId}/4`
  }
  const postData = {
    method: "POST",
    body: JSON.stringify(data),
  };
  const apihots= "https://qjzulsvpt4mg5zluc4cpixhaju0mapkt.lambda-url.us-east-1.on.aws/"
  const res = yield fetch(apihots, postData).then((responce) => responce.json()).catch((e) => console.log(e))
  
  // if(res?.data?.message){
  //   yield put(userActions.scromPackageResponse({...res.data}))
  // }
  // yield call(delay, 2000); 
  // function delay(ms) {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }
  yield put(userActions.setScromLoader(false))
} 

function* getLanguage(){
  const apihost = `${APIConstant.getUrl('api')}courses/getlanguageList`

  const apiCreds = {
    method: "GET",
  };

  const data = yield fetch(apihost, apiCreds).then((responce) => responce.json()).catch((e) => console.log(e))
  if(data?.data.length){
    yield put(userActions.languagePackageResponse([data?.data]))
  }
}

function* getLanguageConfig(action) {
  const { config, configx } = action.payload;
const apihost = `${APIConstant.getUrl('capi')}lmscoursemap/createlocalisationpackage`
  let obj = {};
  let second_lang = [];
  // Payload formation
  Object.keys(configx).forEach(element => {
    const [id, name] = configx[element].split("-");
    const languageObject = {
      id,
      name,
      lan: element
    };
    if (element === "en_US") {

      if (!obj[config]) {
        obj[config] = { languages: [] };
      }
      obj[config].languages.push(languageObject);
    }
    else{
      second_lang.push(id);
    obj[config] = obj[config] ?? {};
    obj[config].languages = obj[config]?.languages || [];
    obj[config]?.languages.push(languageObject);
    }
  });
 
  obj["selected_configs"] = second_lang;

  const headers = {
    "Content-Type": "application/json",
  };
  const postData = {
    method: "POST",
    headers,
    body: JSON.stringify(obj),
  };
  const res = yield fetch(apihost, postData).then((responce) => responce.json()).catch((e) => console.log(e))
    yield put(userActions.languageConfigResponse(res.status))
}





function* getSelectedClientCoursesData(){
  const { clientId } = arguments[0].payload;
 
  const apihost = `${APIConstant.getUrl('storeapi')}lmscoursemap/getusersbyclient/clientid/${clientId}`

  const apiCreds = {
    method: "GET",
  };

  const data = yield fetch(apihost, apiCreds).then((responce) => responce.json()).catch((e) => console.log(e))
  
  if(data?.data?.courses.length){
    const courses={}
    for(let i of data?.data?.courses){
      courses[i.course_id] = i.course_name
    }
    yield put(userActions.selectedClientCoursesDataResponse({[clientId] : {...data.data, courses}}))
  }

}

function* programWriterReset(){
  const data = arguments[0].payload;
  // console.log(data, data.pwReset, data.id)

  const apihost = `${APIConstant.getUrl('api')}aml/saveprogress`
  const headers = {
    "Content-Type": "application/json",
  };
  const apiCreds = {
    method: "POST",
    headers:headers,
    body: JSON.stringify(data.pwReset || {}),
  };


  const res = yield fetch(apihost, apiCreds).then((responce) => responce.json()).catch((e) => console.log(e))
  yield put(userActions.selectedCompanyDataRequest(data?.id || 0))

  // console.log(res)
  
} 
// function* selectedClientDataReq(){
//   const data = arguments[0].payload; // Assuming the form element is passed as payload
//   const apihost = `${APIConstant.getUrl('capi')}predeployconfig/getcelmscourses`
//   const headers = {
//     "Content-Type": "application/json",
//   };
//   const apiCreds = {
//     method: "POST",
//     headers:headers,
//     body: JSON.stringify(data)
//   };
//   const res = yield fetch(apihost, apiCreds).then((responce) => responce.json()).catch((e) => console.log(e))
 
//   yield put(userActions.setSelectedClientDataRes(res?.data.filter(item=>item.player_version_id==="20") || []))
  
// } 

function*courseApprovalReq(){
  const {data,configId} = arguments[0].payload;
  const apihost=`${APIConstant.getUrl('capi')}predeployconfig/updaterplreviewstatus`
  const headers = {
    "Content-Type": "application/json",
  };
  const apiCreds = {
    method: "POST",
    headers:headers,
    body: JSON.stringify(data)
  };
  const res = yield fetch(apihost, apiCreds).then((responce) => responce.json()).catch((e) => console.log(e))
  if(res?.data){
    yield put(userActions.courseApprovalRes(configId))
  }
}

function* quizDownloadReq() {
  const [id, number,name] = arguments[0].payload; // Assuming the form element is passed as payload

  const apihost = `${APIConstant.getUrl('api')}courses/getquizbycrs/${id}/${number}`;
  const headers = {
    "Content-Type": "application/json",
  };
  const apiCreds = {
    method: "GET",
    headers: headers,
  };

  try {
    const res = yield fetch(apihost, apiCreds);
    const responseClone = res.clone();
 
    if (!res.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = yield res.blob();
   
    let json=undefined
    try{
       json = yield responseClone.json();
     
    }
    catch(e){
      json=undefined
    }
    if(json===undefined){
      yield put(userActions.quizDownloadRes(true));
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
  
      // Attempt to extract file name from Content-Disposition header if available
      const contentDisposition = res.headers.get('Content-Disposition');
      let fileName =`${name}.doc`;
  
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="?(.+)"?/);
        if (match) {
          fileName = match[1];
        }
      }
  
      // // Fallback: Log contentDisposition for debugging
      // console.log('Content-Disposition:', contentDisposition);
      
      // // Fallback: Log the URL for debugging
      // console.log('Download URL:', url);
  
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } else {
      yield put(userActions.quizDownloadRes(false));
    }
  } catch (e) {
    console.log('There has been a problem with your fetch operation:', e);
  }
}

function* textDownloadReq(action) {
  const data= action.payload;
  const apihost = `${APIConstant.getUrl('api')}courses/getmoduletextbycrs/${data[0]}/${data[1]}`;
  const headers = {
    "Content-Type": "application/json",
  };
  const apiCreds = {
    method: "GET",
    headers: headers,
  };

  try {
    const res = yield fetch(apihost, apiCreds);
    const responseClone = res.clone();
    if (!res.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = yield res.blob();
   
    let json=undefined
    try{
       json = yield responseClone.json();
  
    }
    catch(e){
      json=undefined
    }
    if(json===undefined){

      yield put(userActions.textDownloadRes(true));
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
  
      // Attempt to extract file name from Content-Disposition header if available
      const contentDisposition = res.headers.get('Content-Disposition');
      let fileName =`${data[2]}.doc`;
  
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="?(.+)"?/);
        if (match) {
          fileName = match[1];
        }
      }
  
      // // Fallback: Log contentDisposition for debugging
      // console.log('Content-Disposition:', contentDisposition);
      
      // // Fallback: Log the URL for debugging
      // console.log('Download URL:', url);
  
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } else {
      yield put(userActions.textDownloadRes(false));
    }
  } catch (e) {
    console.log('There has been a problem with your fetch operation:', e);
  }

}




function processCompanyStats(companyStats) {
  const filteredData = {}
  for (let dt of companyStats?.data) {
      // Extracting date and time components
      const [datePart, timePart] = dt.date.split("T");
      const [year, month, date] = datePart.split("-");
      const [hour, minutes] = timePart.split(":").slice(0, 2);
 
      // Accessing or initializing nested data structures
      const yearData = filteredData[year] || (filteredData[year] = { months: {}, maxVisual: 0 });
      const monthData = yearData.months[month] || (yearData.months[month] = { count: 0, dates: {}, maxVisual: 0 });
      const dateData = monthData.dates[date] || (monthData.dates[date] = { count: 0, hours: {}, maxVisual: 0, data: [] });
      const hourData = dateData.hours[hour] || (dateData.hours[hour] = { count: 0, minutes: {}, maxVisual: 0 });
      const minutesData = hourData.minutes[minutes] || (hourData.minutes[minutes] = { count: 0, data: [] });
 
      // Incrementing counts and storing data
      monthData.count++;
      dateData.count++;
      dateData.data.push(dt);
      hourData.count++;
      minutesData.count++;
      minutesData.data.push(dt);
 
      // Updating maxVisual properties
      yearData.maxVisual = Math.max(yearData.maxVisual, dateData.count);
      monthData.maxVisual = Math.max(monthData.maxVisual, dateData.count);
      dateData.maxVisual = Math.max(dateData.maxVisual, hourData.count);
      hourData.maxVisual = Math.max(hourData.maxVisual, minutesData.count);
  }
  return filteredData
}
 

function* actionWatcher() {
  yield takeLatest(types.ADD_RLMUSER_REQ, addRLMUser);
  yield takeLatest(types.RLMUSER_LOGIN_REQ, rlmUserLogin);
  yield takeLatest(types.ALL_COURSES_REQ, getAllCourses);
  yield takeLatest(types.USER_ASSIGNED_COURSES_RES, getUserAssignedCourses);
  yield takeLatest(types.COURSE_ASSIGNMENT_REQ, courseAssignment);
  
  yield takeLatest(types.USER_COURSE_ASSIGNMENT_REQ, userCourseAssignment);
  
  yield takeLatest(types.COURSE_ASSIGN_REQ, courseAssign);
  
  yield takeLatest(types.ALL_USERS_REQ, getAllUsers);
  yield takeLatest(types.ADMIN_ACL_REQ, getAdminAcl);
  yield takeLatest(types.UPDATE_ACL_REQ, updateAcl);
  yield takeLatest(types.ALL_CLIENT_REQ, getAllClients);
  yield takeLatest(types.LOGGEDIN_CLIENT_COURSES_REQ, getLoggedInClientCourses);
  yield takeLatest(types.CREATE_SCORM_PACK, createScormPackage);
  yield takeLatest(types.LMS_AUTH_REQUEST, getLmsAuthToken);
  yield takeLatest(types.SELECTED_HISTORY_REQ, getHistory);
  yield takeLatest(types.SELECTED_SUPPLIMENTARY_REQ, getSupplimentary);
  yield takeLatest(types.ADD_SUPPLIMENTARY_REQ, addSupplimentary);
  yield takeLatest(types.UPDATE_SUPPLIMENTARY_REQ, updateSupplimentary);
  yield takeLatest(types.ADMIN_TAGS_REQ, getAdminTags);
  yield takeLatest(types.ADMIN_TAGS_UPDATE, updateAdminTags);
  yield takeLatest(types.STATS_DATA_REQ, getStatsData);
  yield takeLatest(types.COMPANY_DATA_REQ, getCompanyNames);
  yield takeLatest(types.SELECTED_COMPANY_DATA_REQ, getSelectedCompanyData);
  yield takeLatest(types.SELECTED_CLIENT_DATA_REQ, getSelectedClientData);
  yield takeLatest(types.SELECTED_CLIENT_COURSES_DATA_REQ, getSelectedClientCoursesData)
  yield takeLatest(types.COURSE_CATEGORY_REQ, getCoursesCategory)
  yield takeLatest(types.USER_CACHE_REQ, getUserCache)
  yield takeLatest(types.ACTIVE_USER_CACHE_REQ, activeUserCache)
  yield takeLatest(types.COURSE_CONFIG_REQ, getCoursesConfig)
  yield takeLatest(types.SCROM_PACKAGE_REQ, getScromPackage)
  yield takeLatest(types.LANGUAGE_PACKAGE_REQ, getLanguage)
  yield takeLatest(types.LANGUAGE_CONFIG_REQ, getLanguageConfig)
  yield takeLatest(types.RESET_PROGRAM_WRITER, programWriterReset)
  // yield takeLatest(types.SET_SELECTED_CLIENT_DATA_REQ, selectedClientDataReq)
  yield takeLatest(types.QUIZ_DOWNLOAD_REQ, quizDownloadReq)
  yield takeLatest(types.TEXT_DOWNLOAD_REQ, textDownloadReq)
  yield takeLatest(types.SET_COURSE_APPROVAL_REQ, courseApprovalReq)
  
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
