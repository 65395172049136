import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './CommonTabPanel.scss'

function TabPanel ( props ) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={ value !== index }
      id={ `simple-tabpanel-${ index }` }
      aria-labelledby={ `simple-tab-${ index }` }
      { ...other }
    >
      { value === index && (
        <Box sx={ { p: 3 } }>
          <div>{ children }</div>
        </Box>
      ) }
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps ( index ) {
  return {
    id: `simple-tab-${ index }`,
    'aria-controls': `simple-tabpanel-${ index }`,
  };
}

const CommonTabPanel = ( { tabs } ) => {

  const [ value, setValue ] = React.useState( 0 );

  const handleChange = ( event, newValue ) => {
    setValue( newValue );
  };
  
  if(!(value < tabs?.length)){setValue( 0 );}

  return (
    <Box sx={ { width: '100%' } } className="common-tab-panel">
      <Box sx={ { borderBottom: 1, borderColor: 'divider' } }>
        <Tabs value={ value } onChange={ handleChange } aria-label="basic tabs example">
          { tabs.map( ( tab, index ) => {
            return <Tab key={ index } label={ tab.name } { ...a11yProps( index ) } />
          } ) }

        </Tabs>
      </Box>
      { tabs.map( ( tab, index ) => {
        return <TabPanel key={ index } value={ value } index={ index }>
          { tab.component }
        </TabPanel>
      } ) }
    </Box>
  );
}

export default CommonTabPanel