import React, { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications";
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
// import * as utilityActions from '../../redux/actions/utilityActions'
import * as userActions from '../../redux/actions/userActions'
import './SignUp.scss'
import { useNavigate } from "react-router-dom";

const SignIn = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(userActions.directLoginReq(false))
    },[])
    const utilityInfo = useSelector(state => state.utility);
    const userInfo = useSelector(state => state.user);

    const [hasSubmitted, userFormSubmitted] = useState(false);
    const [isInvalidUser, setUserInvalid] = useState(false);

    // const alphanumericRegExp = /^[A-Za-z0-9\s]+$/;
    // const alphanumericRegExpNew = /^(?=.*[A-Za-z0-9])(?=.*\d)(?=.*[!@#$%^&*._-])[A-Za-z0-9\d!@#$%^&*._-]{6,30}$/;

    const initialValues ={email: '', password: ''}

    const validationSchema = yup.object().shape( {
        email: yup.string().email( "Invalid email address" ).required( "Required" ),
        // password: yup.string().matches( alphanumericRegExp, ' Password must have 3 characters that include atleast 1 character , 1 number and 1 special character in (!@#$%^&*)' ).required( "Required" ).min(3, "Must be more than 3 characters" ),
        password: yup.string().required(),
    } );


    const isUserLoggedIn = () =>{
        return Object.keys(userInfo.loggedInUser).length  >  0 ? true:false
    }

    const onSubmit = (values) => {
        window.sessionStorage.setItem('p', window.btoa(values.password))
        dispatch(userActions.checkUserInfo(values));
        userFormSubmitted(true)
    }

    const getCurrentYear = () => new Date().getFullYear()
    

    useEffect(()=>{
        if(isUserLoggedIn()){
            navigate('/dashboard')
        }else if(hasSubmitted && !userInfo.isLoggedIn){
            setUserInvalid(true);
        }
    },[userInfo.loggedInUser])


    return <>
    { isInvalidUser && <p className="error-msg">Invalid Username or Password</p>}
    <section className="sign-in-container">
        <div className="container">
            <div className="row">
                <div className="pL0 col-lg-4 col-md-12 col-sm-12">
                    <div className="intro-section">
                        <img src={'https://assets.contentenablers.com/storefront_stg/imgs/ce_new_logo_skill_comp.webp'}  alt='brand-logo'/>
                            <h5>Welcome to <br></br> CE Author Admin</h5>
                            <div className="divider"></div>
                            <p className="copyright-heading">Copyright © Content Enablers {getCurrentYear()}</p>
                            {/* <p>Sign in to continue to your account.</p>
                            <p>Difficulties with login ? Please contact us and we will be happy to help.</p> */}
                    </div>
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                    <Formik
                        initialValues={ initialValues }
                        onSubmit={ onSubmit }
                        validationSchema={validationSchema }
                        enableReinitialize={ true }
                    >
                        { (
                            { values, handleChange, errors, touched, handleSubmit } ) =>
                        (
                            <form className="signup-form" onSubmit={ handleSubmit }
                                onKeyDown={ ( e ) => {
                                    if ( e.key === 'Enter' || e.key == 'Unidentified' ) {
                                        handleSubmit();
                                    }
                                } }>

                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" onChange={ handleChange( 'email' ) } id="email" value={ values.email } label="Email" className="form-control" maxLength="150" autoComplete="off" />
                                    <p className="error-msg">{ errors.email && touched.email && errors.email }</p>
                                 </div>

                                 <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input type="password" onChange={ handleChange( 'password' ) } id="password" value={ values.password } label="Password" className="form-control" maxLength="30" autoComplete="off" />
                                    <p className="error-msg">{ errors.password && touched.password && errors.password }</p>
                                </div>
                          
                                <button type="submit" className="btn btn-sign">SIGN IN</button>
                            </form>
                        ) }
                    </Formik>
                </div>
            </div>
        </div>
    </section>
    </>
}
export default SignIn