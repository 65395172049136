import React, { useEffect } from "react"
import './UsersPanel.scss'
import UsersAccordion from "./accordion/UsersAccordion";
import CommonTabPanel from "./tabs/CommonTabPanel";
import { useDispatch, useSelector } from "react-redux";
import CoursesList from "./CoursesList";
import UsersList from "./UsersList";
import PrivilegeHelper from "../../utility/PrivilegeHelper";
import APIConstant from "../../utility/APIConstant";
import Updates from "./Updates";
import Notes from "./Notes";
import Vignettes from "./Vignettes";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as utilityActions from '../../redux/actions/utilityActions'

const UsersPanel = ({ user }) => {
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.user);
    const clientInfo = useSelector(state => state.client);
    const utilityInfo = useSelector(state => state.utility);

    let tabItems = [];

    useEffect(()=>{
        // console.log("")

    },[userInfo?.allCourses])

    const isClientLoggedIn = () => {
        return user.role === APIConstant.CLIENT_ROLE_ID ? true : false
    }
    const getCourses = () => {
        if (isClientLoggedIn()) {
            console.log("client courses", clientInfo?.courses)
            return clientInfo?.courses?.courses
        }
        console.log("userInfo?.assignedCourses",userInfo?.assignedCourses)
        return userInfo?.assignedCourses;
    }

    const getConfigurations = () => {
        if (Object.keys(clientInfo?.courses).length > 0) {
            return clientInfo?.courses?.configs
        } else {
            return []
        }
    }

    if (utilityInfo.selectedMenuId === 1) {
        if (PrivilegeHelper(user?.role).getAccess()) {
            tabItems = [{ id: 0, name: "All Courses", component: <CoursesList isClient={isClientLoggedIn()} configs={getConfigurations()} courses={userInfo?.allCourses} user={user} /> },
            { id: 1, name: "Users List", component: <UsersList users={userInfo?.allUsers} user={user} /> }]
        } else if (PrivilegeHelper(user?.role).getClientCourses() && userInfo?.clients.length > 0) {
            tabItems = [{ id: 0, name: "Assigned Courses", component: <CoursesList isClient={isClientLoggedIn()} configs={[]} courses={getCourses()} user={user} /> },
            { id: 1, name: "Client Courses", component: <CoursesList isClient={isClientLoggedIn()} configs={getConfigurations()} courses={getCourses()} user={user} /> }]
        }
        else {
            tabItems = [{ id: 0, name: "Assigned Courses", component: <CoursesList isClient={isClientLoggedIn()} configs={getConfigurations()} courses={getCourses()} user={user} /> }]
        }
    } else if (utilityInfo.selectedMenuId === 4) {
        tabItems = [{ id: 0, name: "UPDATES", component: <Updates /> },
        { id: 1, name: "VIGNETTES", component: <Vignettes /> }, { id: 1, name: "NOTES", component: <Notes /> }]
    }


        const isSideNavOpen = utilityInfo.isSideNavOpen ? 'nav-open' : 'nav-close';
        const setbackMenu = ()=>{
            dispatch(utilityActions.setSelectedMenu(1))
        }  
        return <section className={`user-container ` + isSideNavOpen} >
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    {utilityInfo?.selectedCourseUpdates?.coursename && <div className="sup-holder"> <div  onClick={()=>setbackMenu()}><ArrowBackIcon className='arrow-back' /></div>
                        <div className="sup-cname"><p className="supplimentary_cname">{utilityInfo?.selectedCourseUpdates?.coursename}</p></div></div>}
                    <CommonTabPanel tabs={tabItems} />
                </div>
            </div>
        </section>
    }
    export default UsersPanel